@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@font-face {
  font-family: "Domaine Text Regular";
  src: url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-regular.eot");
  src: url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-regular.eot?#iefix") format("embedded-opentype"), url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-regular.woff2") format("woff2"), url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Domaine Text Black";
  src: url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-black.eot");
  src: url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-black.eot?#iefix") format("embedded-opentype"), url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-black.woff2") format("woff2"), url("https://giddyup.io/wp-content/themes/tif-theme/css/fonts/domaine/domaine-text-web-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

body {
  overflow: auto !important;
}


